document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger);

  // Array de pasos y mockups para facilitar la animación
  const stepsAndMockups = [
    { step: "#step1", mockup: ".mockup1" },
    { step: "#step2", mockup: ".mockup2" },
    { step: "#step3", mockup: ".mockup3" },
  ];

  stepsAndMockups.forEach((elem, index) => {
    // Inicialmente oculta todos los mockups y steps excepto el primero
    if (index !== 0) {
      gsap.set([elem.step, elem.mockup], { autoAlpha: 0 });
    }

    ScrollTrigger.create({
      trigger: elem.step,
      start: "top+=30% center", // Ajusta este valor según necesites para iniciar más temprano o tarde
      end: "bottom-=30% center", // Ajusta este valor para controlar dónde termina
      scrub: 1, // Vincula la animación al scroll, 1 para un efecto suave, aumenta para un scroll más largo
      onEnter: () => showStepAndMockup(index),
      onLeaveBack: (self) => showStepAndMockup(index - 1 >= 0 ? index - 1 : 0),
      markers: false, // Para depuración, puedes remover esto luego
    });
  });

  function showStepAndMockup(index) {
    // Ocultar todos los pasos y mockups
    gsap.to(".step", { autoAlpha: 0, duration: 0.5 });
    gsap.to(".mockup", { autoAlpha: 0, duration: 0.5 });

    // Mostrar el paso y mockup actual
    gsap.to(stepsAndMockups[index].step, { autoAlpha: 1, duration: 0.5 });
    gsap.to(stepsAndMockups[index].mockup, { autoAlpha: 1, duration: 0.5 });
  }
});

// Asegúrate de que el documento esté completamente cargado antes de ejecutar el código
document.addEventListener("DOMContentLoaded", function () {
  // Inicialización del primer Swiper
  var swiper = new Swiper(".mySwiper", {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".custom-next",
      prevEl: ".custom-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  });

  // Eventos de clic para el primer Swiper
  document.querySelector(".custom-prev").addEventListener("click", function () {
    swiper.slidePrev();
  });

  document.querySelector(".custom-next").addEventListener("click", function () {
    swiper.slideNext();
  });

  // Inicialización del segundo Swiper
  var swiper2 = new Swiper(".mySwiper2", {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    navigation: {
      nextEl: ".custom-next2",
      prevEl: ".custom-prev2",
    },
    on: {
      init: function () {
        const paginationContainer = document.querySelector('.my-custom-pagination2');
        this.slides.forEach((slide, index) => {
          const bullet = document.createElement('span');
          bullet.classList.add('custom-pagination-bullet');
          bullet.addEventListener('click', () => { this.slideTo(index); });
          paginationContainer.appendChild(bullet);
        });
        updateActivePaginationBullet(this);
      },
      slideChange: function () {
        updateActivePaginationBullet(this);
      }
    }
  });

  function updateActivePaginationBullet(swiper) {
    let activeIndex = swiper.realIndex;
    let bullets = document.querySelectorAll('.my-custom-pagination2 .custom-pagination-bullet');
    bullets.forEach((bullet, index) => {
      if (index === activeIndex) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }
});
